.main {
    position: relative;

    &-translationWrapper {
        position: absolute;
        left: 0;
        top: 0;
    }

    .window {
        width: 100%;
        height: 100%;
    }

    > canvas {
        position: absolute;
        width: 100vw;
        height: 100vh;
        pointer-events: none;
        z-index: 1000;
    }
}