@use 'sass:math';

.application-files-root {
    background-color: #0c6a76b0;
    font-family: 'Roboto';
    font-weight: 300;

    ul {
        list-style: none;
        margin: 0;
    }

    &-path {
        display: flex;
        align-items: center;
        color: black;
        padding: 0.5rem;
    }

    &--dark &-path {
        background-color: white;
        box-shadow: 0px 0px 4px 2px rgb(0, 0, 0, 0.5);
    }

    &--light &-path {
        background-color: #0000002e;
    }

    &-path li:not(:last-child) {
        margin-right: 0.5rem;
    }

    &-path button {
        border: none;
        padding: 0.5rem 1rem;
        color: white;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    &--dark &-path button {
        background-color: #155962;
    }

    &--dark &-path li:hover button {
        background-color: lighten(#155962, 10%);
    }

    &--light &-path button {
        background-color: #eee;
        color: black;
    }

    &--light &-path li:hover button {
        background-color: lighten(#eee, 10%);
    }

    &-currentDirectory {
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
    }

    &--dark &-currentDirectory {
        background-color: #eee;
        color: black;
    }

    &--light &-currentDirectory {
        background-color: #16626b;
        color: white;
    }

    &-files {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        padding: 1.5rem;
    }

    &-files li {
        width: 12rem;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &-files svg {
        font-size: 2.5rem;
        margin-right: 1rem;
    }

    &-files li:hover span {
        text-decoration: underline;
    }

    &-files span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: math.div(14, 16) * 1rem;
    }
}
