.terminal {
    color: var(--default);
    display: flex;
    flex-direction: column;

    &--darkTheme {
        background-color: #0c6a76b0;
        --red: #ff8383;
        --red-weight: bold;
        --green: #73fe3f;
        --yellow: #ffe14f;
        --blue: #47cbff;
        --blue-weight: bold;
        --purple: #f38ef3;
        --cyan: #00ffff;
        --default: #d8f5f8;
    }

    &--lightTheme {
        background-color: #0c6a76d6;
        --red: #ffadc7;
        --green: #49ff38;
        --yellow: #ffd400;
        --blue: #47eaff;
        --purple: #ffb0ff;
        --cyan: #7de6e6;
        --default: white;
    }

    pre {
        margin: 0;
    }

    svg {
        font-size: 0.65rem;
        margin-right: 0.6rem;
        margin-top: 0.2rem;
    }

    &-history {
        padding: 1rem;
    }

    &-historyEntry:not(:last-child) {
        margin-bottom: 2rem;
    }

    &-historyEntry > *:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    &-historyError {
        color: var(--red);
        font-weight: var(--red-weight);
    }

    &-historyInput {
        display: flex;
    }

    &-processing {
        margin: -0.5rem 1rem 1rem 1rem;
    }

    &-processing,
    textarea::placeholder {
        color: rgba(255, 255, 255, 0.3);
    }

    &-input {
        flex-grow: 1;
        display: flex;
        padding: 1rem;
    }

    textarea {
        flex-grow: 1;
        background-color: transparent;
        border: none;
        outline: none;
        color: var(--default);
        padding: 0;
        font-size: inherit;
        resize: none;
    }

    &-coloredText--red {
        color: var(--red);
        font-weight: var(--red-weight);
    }

    &-coloredText--green {
        color: var(--green);
        font-weight: var(--green-weight);
    }

    &-coloredText--yellow {
        color: var(--yellow);
        font-weight: var(--yellow-weight);
    }

    &-coloredText--blue {
        color: var(--blue);
        font-weight: var(--blue-weight);
    }

    &-coloredText--purple {
        color: var(--purple);
        font-weight: var(--purple-weight);
    }

    &-coloredText--cyan {
        color: var(--cyan);
        font-weight: var(--cyan-weight);
    }

    &-coloredText--default {
        color: var(--default);
        font-weight: var(--default-weight);
    }
}
